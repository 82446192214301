'use client';

import { type FC, useEffect, useState } from 'react';

import type { ArticlePreview } from '@hyex/dxp/types/blog/Article';
import type { BlogCategory } from '@hyex/dxp/types/blog/Category';
import UltraCartService from '@hyex/modules/portal/ultracart/uc.cart.service';
import Modal, { useModalDialog } from '@hyex/ui/Modal';
import cart from '/public/images/icons/cart-icon.svg';
import Cart from 'app/components/cart';

import PortalHeaderNav from './HeaderNav';
import { useSearchParams } from 'next/navigation';
import { getCookieValue } from '@hyex/modules/utils/cookie/getCookieValue';
import {
  COOKIE_DOMAIN,
  DEFAULT_ULTRACARD_COOKIE_DAYS,
  DEFAULT_OPTOUT_COOKIE_DAYS,
} from '@hyex/modules/portal/constants';
import { ProductCategory } from '@hyex/dxp/types/store/Product/ProductCategory';
import { PortalIcon, PortalLogo } from './logo';
import SiteSearch from '@hyex/modules/shared/site-search/SiteSearch';
import NavButton from '@hyex/ui/nav/NavButton';
import Image from 'next/image';
import { NavBase } from '@hyex/modules/shared/Nav/NavBase';
import { createCookie } from '@hyex/modules/utils/cookie/createCookie';
import { ProductPreview } from '@hyex/dxp/types/store/Product/Product';

type PropType = {
  articles: ArticlePreview[];
  products: ProductPreview[];
  blogCategories: BlogCategory[];
  productCategories: ProductCategory[];
  susppended?: boolean;
};

const PortalHeader: FC<PropType> = ({
  products,
  articles,
  blogCategories,
  productCategories,
  susppended,
}) => {
  const [itemsInCart, setItemsInCart] = useState(0);

  const rightSideItems = (
    <>
      <SiteSearch baseHref={'/learn/search/'} />
      <NavButton className="cart-btn" onClick={() => handleActivate && handleActivate()}>
        <div className="relative">
          <span className="cart-btn-items">{itemsInCart}</span>
          <Image src={cart} alt={'Your cart'} width={34} height={34} />
        </div>
      </NavButton>
    </>
  );

  const leftSideItems = (
    <PortalHeaderNav
      latestArticles={articles}
      blogCategories={blogCategories}
      productCategories={productCategories}
      products={products}
    />
  );

  if (susppended)
    return (
      <>
        <header>
          <div className="container">
            <NavBase
              logo={PortalLogo}
              icon={PortalIcon}
              beforeLogo={leftSideItems}
              afterLogo={rightSideItems}
            />
          </div>
        </header>
      </>
    );

  const router = useSearchParams();

  const modalDialog = useModalDialog();

  const [isActivate, setActive] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const couponValue = router.get('coupon');

    if (couponValue) {
      const existedCookie = getCookieValue('coupon');

      if (existedCookie !== couponValue) {
        createCookie('coupon', couponValue, DEFAULT_ULTRACARD_COOKIE_DAYS, COOKIE_DOMAIN);
      }
    }
  }, [router]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const optOutValue = router.get('optout');

    if (optOutValue) {
      const existedCookie = getCookieValue('optout');

      if (existedCookie !== optOutValue) {
        createCookie('optout', optOutValue, 0, COOKIE_DOMAIN);
      }
    }
  }, [router]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    // Retrieve current URL parameters
    const paramsValue = window.location.search;

    if (paramsValue) {
      // Check if the cookie already exists with the same value
      const existingCookie = getCookieValue('params');

      if (existingCookie !== paramsValue) {
        // Save the query parameters into a cookie
        createCookie('params', paramsValue, 0, COOKIE_DOMAIN);
      }
    }
  }, [router]);

  const handleActivate = () => {
    const total = UltraCartService.getItems()?.length ?? 0;

    if (total === 0) {
      setActive(false);

      return;
    }

    if (!isActivate) {
      setActive(true);
    } else {
      setActive(false);
    }

    modalDialog.open();
  };

  useEffect(() => {
    if (itemsInCart === 0) {
      setActive(false);

      const timer = setTimeout(() => {
        modalDialog.close();
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [itemsInCart]);

  useEffect(() => {
    const total = UltraCartService.getItems()?.length ?? 0;

    setItemsInCart(total);

    const handleCartChange = (beforeStateNull: boolean) => {
      const newLength = UltraCartService.getItems()?.length ?? 0;

      setItemsInCart(newLength);

      if (!beforeStateNull) handleActivate();
    };

    UltraCartService.subscribe(handleCartChange);

    return () => UltraCartService.unsubscribe(handleCartChange);
  }, [router]);

  const modal = () => {
    return (
      <Modal
        {...modalDialog}
        onClose={() => {
          setActive(false);
        }}
        classes={{
          wrapper: 'wrapper-modal-cart',
          body: 'body-modal-cart-class',
          content: 'content-modal-cart-class',
        }}
      >
        <Cart render={isActivate} />
      </Modal>
    );
  };

  return (
    <>
      <header>
        <div className="container">
          <NavBase
            logo={PortalLogo}
            icon={PortalIcon}
            beforeLogo={leftSideItems}
            afterLogo={rightSideItems}
          />
        </div>
      </header>
      {modal()}
    </>
  );
};

export default PortalHeader;
